.main {
    background-color: #010101;
    background: #010101;
}

.container-xxl.coupon-wallet-body {
    background-color: #010101;
    padding: 0% !important;
}

.container-xxl.coupon-wallet-body {
    width: 100%;
}

.balance-section {
    background-color: #101010;
    width: 100%;
    /* margin-top: 0; */
    flex-direction: column;
    padding: 3rem 1rem 1.5rem 1rem;
    justify-content: center;
}

.balance-section .balance-figure {
    justify-content: center;
    flex-direction: column;
}

.coin-info img {
    background-color: #3e3e3e;
    padding: 2px;
}

.balance-section h5 {
    opacity: 0.7;
    font-size: 1rem;
}

.balance-section h3 {
    font-weight: 500;
}

.balance-section button {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    transition: all 0.3s;
    width: 100%;
    padding: 0.5rem;
    font-size: 0.8rem !important;
    border: none;
    color: #fff;
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
}

.action-buttons-container {
    width: 100%;
    gap: 0.6rem;
}

.balance-section button:hover {
    opacity: 0.5;
}

.container-xxl.coupon-wallet-body .card-header {
    padding: 1rem 0;
    gap: 5px !important;
}

.container-xxl.coupon-wallet-body .card-header .nav-item .nav-link.active {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border: none !important;
    background-color: #1c1c20;
    padding: 0;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;

}

.container-xxl.coupon-wallet-body .card-header .nav-item .nav-link.active span {
    background-color: #1c1c20;
    margin: 1.5px;
    width: 100% !important;
    border-radius: 5px;
    padding: 8px 16px;
    height: auto;
    box-sizing: border-box !important;
}

.container-xxl.coupon-wallet-body .tab-navigation {
    border-radius: 0 5px 5px 0 !important;
}

.container-xxl.coupon-wallet-body .card-header .tab-navigation {
    gap: 5px;
}
  
/* Table Styling */

.container-xxl.coupon-wallet-body .tab-content {
    margin-bottom: 3.5rem;
}

.table-container {
    background-color: #1b1b20;
    align-items: center;
    flex-grow: 1;
}

.table-body {
    background-color: #1b1b20 !important;
    font-size: 0.8rem;
    align-items: center;
    width: 100%;
}

.table-header .table-row {
    text-align: center;
    justify-content: center !important;
}

.table-header {
    width: 100%;
    justify-content: center !important;
    align-items: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
    /* padding: 10px 15px; */
}

.table-header .table-row {
    width: 100%;
    background-color: #28282d;
    /* border-radius: 5px; */
    /* justify-content: center !important; */
}

.table-header h3 {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0;
}

.table-cell {
    
    display: flex;
}

.table-header, .table-row {
    display: flex;
    /* justify-content: space-between; */
    background-color: #1b1b20;
}

.table-body .table-row {
    background-color: #1b1b20;
    background: #1b1b20;
    justify-content: center;
    box-shadow: none;
}

.container-xxl.coupon-wallet-body .table-row {
    padding: 0 1rem;
}

.table-row {
    flex: 1 1 calc(33.33% - 5px); /* Set width for 4 items per row */
    max-height: calc(33.33% - 5px); /* Prevent overflow */
    width: 100%;
    gap: 10px;
    align-items: center;
}

.table-cell {
    flex: 1;
    text-align: left;
    padding: 10px;
}

.table-cell img {
    margin-right: 10px;
    vertical-align: middle;
}

.action-buttons {
    display: flex;
    gap: 10px;
}

/* Earnings Section */
.totalEarnings-container {
    background-color: #1b1b20;
    padding: 15px;
    border-radius: 8px;
}

.totalEarnings-container-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.earning-headings {
    font-weight: bold;
    font-size: 1rem;
    color: #333;
}

.bank-account-container .top-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

/* Referral Card */
.referral-earning-card {
    background-color: #1b1b20;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.referral-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.coin-column, .user-column {
    max-width: 85px;
}

.amount-column, .earnings-column {
    max-width: 78px;
}

.action-column, .earned-date-column {
    max-width: 150px;
    width: 150px;
}

.table-body .action-buttons, .earned-date-column span {
    width: 100%;
}

.table-body .action-buttons .action-button-container {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 5px;
    align-items: stretch;
    box-sizing: border-box !important;
    /* padding: 1px; */
    flex: 1 1 calc(50% - 5px); 
    /* max-width: calc(50% - 5px);  */
}

.table-body .action-buttons button {
    background-color: #010101;
    padding: 5px;
    color: #fff;
    border-radius: 5px;
    border: none;
    font-size: 0.7rem;
    width: 100%;
    margin: 1px;
}

/* Earnings tab --------------------------------- */

.container-xxl.coupon-wallet-body .total-earnings-section {
    background-color: #141414;
    flex-direction: column;
}

.container-xxl.coupon-wallet-body .totalEarnings-container {
    border: none;
    border-radius: 0;
    padding: 1.5rem 1rem 2rem 1rem;
}

.container-xxl.coupon-wallet-body .totalEarnings-container button {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);

}

.container-xxl.coupon-wallet-body .bank-details-section {
    background-color: #1c1c21;
    /* width: 100%; */
}

.container-xxl.coupon-wallet-body .bank-account-container {
    width: 100%;
}

.container-xxl.coupon-wallet-body .bank-details-section .bank-account-container {
    border-radius: 0;
}

.bank-account-container {
    align-items: center;
    padding: 1rem 1rem 2rem 1rem !important;
}

.upper-section-container {
    width: 100%;
}

.bank-account-container .top-container .upper-section {
    justify-content: space-between;
    width: 100%;
}

.bank-account-container .top-container {
    gap: 0 !important;
    align-items: center;
}

.bank-details-head {
    gap: 8px;
    align-items: center;
}

.bank-account-container .update-bank-container {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 5px;
    transition: all 0.3s;
    padding: 1px !important;
    font-size: 0.6rem !important;
    height: auto;
    width: auto;
    box-sizing: border-box;
}

.bank-account-container .update-bank-container:hover {
    opacity: 0.7;
}

.bank-account-container .update-bank-container span {
    background-color: #010101;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    color: #fff !important;
    box-sizing: border-box;
}

.bank-acc-details {
    background-color: #28282c;
    width: calc(100% + 26px); 
    display: flex;
    justify-content: space-between;
}

.bank-acc-details span {
    margin: 0 13px;
    font-size: 0.75rem;
}

.container-xxl.coupon-wallet-body .referral-earnings-container {
    background-color: #17171b;
    padding: 1rem 0 0 0;
}

.totalEarnings-container-row .header {
    opacity: 0.8;
}

.container-xxl.coupon-wallet-body .referral-earnings-container .table-header {
    background-color: #17171b;
}

.container-xxl.coupon-wallet-body .referral-earning-card {
    border: none;
    border-radius: 0;
    color: #fff;
    background-color: #151519;
}

.container-xxl.coupon-wallet-body .referral-earning-card .table-cell span {
    color: #fff !important;
}

.container-xxl.coupon-wallet-body .user-column div {
    flex-direction: column;
    align-items: start;
    justify-content: start;
}

.container-xxl.coupon-wallet-body .earning-headings {
    font-size: 0.8rem;
    padding-bottom: 1rem;
    margin-left: 13px;
    color: #fff;
}

/* .container-xxl.coupon-wallet-body .bank-account-container {
    width: 100%;
} */

.container-xxl.coupon-wallet-body .referral-earnings-container .table-row {
    justify-content: center;
}

@media (min-width: 500px) {
    .container-xxl.coupon-wallet-body .table-header, .container-xxl.coupon-wallet-body .table-row {
        justify-content: space-between !important;
    }
    .container-xxl.coupon-wallet-body .table-row {
        padding: 0 2rem;
    }
}

@media (min-width: 610px) {

    .container-xxl.coupon-wallet-body .balance-section {
        padding: 3rem 2rem 2.5rem 2rem;
    }

    .container-xxl.coupon-wallet-body .action-buttons-container button {
        font-size: 0.9rem !important;
    }

    .balance-section h5 {
        font-size: 1rem;
    }

    .balance-section h3 {
        font-size: 1.5rem;
    }

    .container-xxl.coupon-wallet-body .card-header .nav-item .nav-link.active {
        background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
        border: none !important;
        background-color: #1c1c20;
        padding: 0;
        width: 100% !important;
        height: 100% !important;
        display: flex;
        align-items: center;
    }

    .container-xxl.coupon-wallet-body .card-header .nav-item span {
        font-size: 0.8rem;
    }

    .container-xxl.coupon-wallet-body .card-header .nav-item .nav-link.active span {
        background-color: #1c1c20;
        margin: 1.5px;
        width: 100% !important;
        border-radius: 5px;
        height: auto;
        box-sizing: border-box !important;
        padding: 8px 24px !important;
    }

    .container-xxl.coupon-wallet-body .tab-navigation {
        padding: 0.5rem;
    }

    .container-xxl.coupon-wallet-body .tab-navigation span {
        padding: 5px 0.5rem !important;
    }

    .container-xxl.coupon-wallet-body .tab-navigation .nav-link {
        height: 100%;
    }

    .container-xxl.coupon-wallet-body .table-container {
        padding-top: 2rem;
    }

    .container-xxl.coupon-wallet-body .table-header h3, .container-xxl.coupon-wallet-body .table-row span {
        font-size: 0.9rem;
    }

    .container-xxl.coupon-wallet-body .table-body .table-cell {
        padding: 1rem 1rem;
    }

    .container-xxl.coupon-wallet-body .action-column button {
        padding: 5px 1rem;
        font-size: 0.9rem;
    }

    .action-column, .earned-date-column {
        max-width: 200px;
        width: 200px;
    }

    .coin-column, .user-column {
        max-width: 250px;
        flex-direction: row;
    }

    .user-column div {
        align-items: center !important;
    }

    .user-column div {
        width: 100%;
        flex-direction: row !important;
    }

    .amount-column, .earnings-column {
        max-width: 200px;
    }

    .table-body {
        font-size: 0.9rem;
    }

    .totalEarnings-container button {
        font-size: 0.9rem !important;
        border-radius: 10px;
    }
    
    .bank-acc-details span {
        font-size: 0.9rem;
    }

    .container-xxl.coupon-wallet-body .acc-edit-btn {
        padding: 1rem !important;
        font-size: 0.8rem;
    }

    .bank-details-head, .earning-headings span, .earning-headings {
        font-size: 0.9rem;
    }

    .container-xxl.coupon-wallet-body .acc-edit-btn {
        padding: 8px !important;
        font-size: 0.6rem;
    }

    .totalEarnings-container-row span {
        font-size: 0.9rem;
    }
}

@media (min-width: 1024px) {
    .container-xxl.coupon-wallet-body {
        width: 774px !important;
    }

    .container-xxl.coupon-wallet-body .earning-headings {
        margin: 0 32px;
        padding-top: 20px;
    }

    .coupon-wallet-sidebar {
        max-width: 250px;
    }

    .container-xxl.coupon-wallet-body .balance-section {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 2rem;
        align-items: center;
    }

    .container-xxl.coupon-wallet-body .balance-section .action-buttons-container {
        width: auto;
    }

    .balance-section .action-buttons-container button {
        padding: 8px 28px;
    }

    .container-xxl.coupon-wallet-body .tab-navigation {
        margin-left: 8px;
        border-radius: 10px !important;
        overflow: hidden;
    }

    .container-xxl.coupon-wallet-body .coupon-content-body, .tab-body-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .container-xxl.coupon-wallet-body .balance-section{
        width: 754px;
        margin-top: 1rem;
        border-radius: 10px;
    }

    .container-xxl.coupon-wallet-body .card-body {
        display: flex;
        justify-content: center;
    }
    .container-xxl.coupon-wallet-body .table-container, .total-earnings-section, .bank-account-container, .referral-earnings-container {
        width: 754px !important;
        border-radius: 10px;
        overflow: hidden;
    }

    .tab-body-content {
        gap: 1rem;
    }

    .earning-bank-section {
        width: 754px !important;
        flex-direction: row !important;
    }

    .earning-bank-section .earning-item {
        width: calc(50% - 0.5rem);
    }

    .container-xxl.coupon-wallet-body .table-header .table-row {
        padding: 0 5px;
        margin: 0 30px;
        border-radius: 5px;
    }

    .bank-account-container .top-container {
        padding: 10px 10px;
    }

    .bank-acc-details {
        width: 337px;
        border-radius: 5px;
    }
    .bank-details-section {
        border-radius: 10px;
    }

    .bank-account-container {
        padding: 1rem 1rem 0.5rem 1rem !important;
    }

    .container-xxl.coupon-wallet-body .totalEarnings-container {
        padding: 1.5rem 1rem 1.5rem 1.5rem;
    }

    .total-earnings-upper-subsection {
        flex-direction: row !important;
        justify-content: space-between;
    }

    .total-earnings-upper-subsection .totalEarnings-container-row {
        flex-direction: column !important;
    }

    .total-earnings-upper-subsection {
        padding: 0 0.5rem;
    }

    .bal-figure {
        font-size: 1.2rem !important;
    }
}

@media (min-width: 1200px) {
    .container-xxl.coupon-wallet-body {
        width: 950px !important;
    }

    .earning-bank-section {
        width: 900px !important;
    }

    .bank-details-section {
        border-radius: 10px;
    }

    .bank-account-container {
        padding: 1rem 1rem 0.5rem 1rem !important;
    }

    .container-xxl.coupon-wallet-body .card-header {
        padding: 1rem 1rem;
    }

    .container-xxl.coupon-wallet-body .balance-section{
        width: 900px;
    }

    .bank-acc-details {
        width: 390px;
    }

    .container-xxl.coupon-wallet-body .table-container, .total-earnings-section, .bank-account-container, .referral-earnings-container {
        width: 900px !important;
    }

    .upper-section-container {
        width: 390px;
    }
}

@media (min-width: 1440px) {
    /* .container-xxl.coupon-wallet-body {
        margin: 0 !important;
    } */

    .coupon-wallet-sidebar.profile-sidebar {
        max-width: 300px !important;
        width: 300px;
    }

    .container-xxl.coupon-wallet-body {
        width: 1040px !important;
    }

    .container-xxl.coupon-wallet-body .card-header {
        padding: 1rem 1rem;
    }

    .container-xxl.coupon-wallet-body .balance-section{
        width: 1000px;
        margin-top: 1rem;
        border-radius: 10px;
    }

    .bank-acc-details {
        width: 968px;
        border-radius: 5px;
    }

    .container-xxl.coupon-wallet-body .table-container, .total-earnings-section, .bank-account-container, .referral-earnings-container {
        width: 1000px !important;
    }

    .container-xxl.coupon-wallet-body .table-cell img {
        width: 50px !important;
        height: 50px !important;
    }

    .bank-acc-details {
        width: 436px;
    }

    .container-xxl.coupon-wallet-body .table-container, .total-earnings-section, .bank-account-container, .referral-earnings-container {
        width: 1000px !important;
    }

    .upper-section-container {
        width: 436px;
    }

    .earning-bank-section {
        width: 1000px !important;
    }

    .bank-account-container {
        gap: 1.5rem !important;
        padding: 2rem 2rem 1.5rem 2rem !important;
    }
}

@media (min-width: 2000px) {
    .coupon-wallet-sidebar.profile-sidebar {
        max-width: 350px !important;
    }
}