.persona-container-modal {
    font-family: poppins;
}

.persona-container{
    background-color: #D1D4FF1A;
    padding: 10px;
    border-radius: 16px;
}

.persona-title{
    text-align: center;
    font-size: small;
}

.persona-image-container{
    background-color: #B2B2B224;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 20px;
}

.persona-image-frame{
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 50px;
    padding: 2px;
}

.persona-image{
    background-color: #B2B2B224;
    border-radius: 50px;
    border: 2px solid #272727;
    width: 75px;
    height: 75px;
}

.current-persona-image-container{
    display: flex;
    flex-direction: column;
    background-color: #B2B2B224;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 8px;
}

.current-persona-image{
    display: flex;
    width: 100%;
    background-color: #B2B2B224;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.current-persona-avatar{
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 50px;
    padding: 2px;
}

.add-profile-photo-container{
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: small;
    gap: 10px;
}

.add-profile-photo-button{
    all: unset;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    border-radius: 50px;
}

.add-profile-photo-icon{
    width: 75px;
    height: 75px;
    align-self: center;
    object-fit: cover;
    overflow: hidden;
    border-radius: 50px;
}

.persona-name-container{
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: small;
}

.persona-name-input{
    all: unset;
    background-color: #D1D4FF1A;
    color: white;
    font-size: small;
    text-align: center;
    border-radius: 8px;
    padding: 10px;
}

.persona-description-container{
    background-color: #D1D4FF1A;
    color: white;
    font-size: small;
    border-radius: 8px;
    margin: 2px 10px 20px 10px;
    padding: 5px;
}

.persona-description-textarea{
    all: unset;
    width: 100%;
    overflow: hidden;
    flex-grow: 1;
    resize: none;
    text-align: center;
    overflow-x: hidden;
}

.character-count{
    text-align: right;
    font-size: small;
    color: white;
}

.persona-button-container{
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 10px;
    width: 100%;
}

.next-button{
    all: unset;
    align-items: center;
    justify-content: center;
    display: flex;
}

.next-button-content{
    padding: 10px;
    color: white;
    font-size: small;
    text-align: center;
}

.back-button{
    all: unset;
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 10px;
    padding: 1px;
}

.back-button-content{
    display: block;
    background-color: #272727;
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-size: small;
    text-align: center;
}

.all-persona-modal-container{
    width: 100%;
}

.persona-card{
    background-color: #D1D4FF1A;
    border-radius: 8px;
    padding: 10px;
    margin: 10px;
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.persona-card-image{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #B2B2B224;
    object-fit: cover;
}

.persona-card-details{
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-left: 10px;
}

.persona-card-description{
    font-size: small;
    color: #C2C2C2;
}

.persona-card-switch-button{
    all: unset;
}

.persona-selected-icon{
    color: #F7DE5E;
    font-size: 20px;
}

.persona-unselected-icon{
    color: white;
    font-size: 20px;
}