.storychat-modal {
    background-color: #D1D4FF1A;
    border-radius: 16px;
    padding: 12px;
    font-size: small;
}

.storychat-modal input {
    all: unset;
    background-color: #D1D4FF1A;
    color: white;
    font-size: small;
    text-align: center;
    border-radius: 8px;
    padding: 5px;
}

.storychat-modal textarea {
    all: unset;
    background-color: #D1D4FF1A;
    color: white;
    font-size: small;
    text-align: center;
    border-radius: 8px;
    padding: 5px;
}

.create-image-container {
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    background-color: #D1D4FF1A;
    border-radius: 18px;
}

.create-storychat-modal-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
}

.replace-image-button {
    all: unset;
    position: absolute;
    z-index: 1;
    background-color: #F7DE5E;
    padding: 10px;
    border-radius: 50px;
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-storychat-modal-input-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 10px 0px;
}

.create-storychat-modal-switch-option{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
    padding: 10px;
    background-color: #D1D4FF1A;
    border-radius: 8px;
}

.create-storychat-modal-switch-option-switch .form-check-input{
    height: 1em;
    width: 2em;
    font-size: 1rem;
    color: white;
}

.back-button{
    all: unset;
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 10px;
    padding: 1px;
}

.back-button-content{
    display: block;
    background-color: #272727;
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-size: small;
    text-align: center;
}

.next-button{
    all: unset;
    align-items: center;
    justify-content: center;
    display: flex;
}

.next-button-content{
    padding: 10px;
    color: white;
    font-size: small;
    text-align: center;
}

.create-storychat-modal-button-container{
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 10px;
    width: 100%;
}

.create-storychat-button{
    all: unset;
    background-color: #D1D4FF1A;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px;
}

.storychat-item-card{
    all: unset;
    display: flex;
    flex-direction: row;
    background-color: #D1D4FF1A;
    padding: 10px;
    border-radius: 8px;
    margin: 10px 0px;
    align-items: center;
    justify-content: space-between;
    width: 97%;
}

.storychat-item-card-image {
    background-color: gray;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: cover;
}

.storychat-item-card-text{
    width: 80%;
}

.storychat-item-card-message{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.storychat-selected-icon{
    color: #F7DE5E;
    font-size: 20px;
}

.storychat-unselected-icon{
    color: white;
    font-size: 20px;
}

.delete-storychat-modal{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}