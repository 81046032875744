.view-creator-profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0em;
    gap: 1em;
    /* align-items: flex-start; */
    background: #010101;
}

@media only screen and (max-width: 1473px) {
    .profiles-tab-container {
        width: 100%!important;
        box-sizing: border-box !important;
    }
}

.profiles-tab-container {
    /* border: 1px solid #B7C1D3; */
    width: 70%;
    box-sizing: border-box !important;
}

.profiles-tab-container-tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 1;
    color: #B7C1D3;
}

.profiles-selected-tab {
    color: var(--theme-color);
    border-bottom: 2px solid var(--theme-color);
}

.add-youtube-button {
    background-color: var(--theme-color) !important;
    width: 98% !important;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin: 20px 5px;
}

.youtube-tab-bar{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0em 6em;
    width: 100%;
    align-self: center;
    
}

.profiles-tab-content {
    height: 80vh;
    padding: 0em 2em;
    justify-content: center;
    overflow-y: scroll;
}

.character-card-container {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 20px 8px 80px 0 #0000000a;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 2em;
    width: fit-content;
}

.character-card-social-container {
    display: flex;
    gap: 1em;
}

.character-card-social {
    width: 30px;
    height: 30px;
}

.character-card-social img {
    width: 100%;
    height: 100%;
}

.user-created-characters {
    overflow-y: scroll;
}

.sidebar-content {
    width: 260px !important;
}

.profile-sidebar{
    display: none !important;
}

.cover-photo {
    background-size: cover;
    background-position: center;
    border-radius: 10 10 0 0;
    height: 100px;
}

.cover-photo img {
    object-fit: cover;
    z-index: 1;
}

.user-card-container-box {
    display: flex;
    flex-direction: column;
    justify-content: flex;
    max-width: 100% !important;
    background: #0a0a0a; 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    padding-bottom: 0.5rem;
    border-radius: 0px !important;
}


.profile-card {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)) !important;
    flex-direction: column;
}

.profile-card p {
    font-size: 0.8rem !important;
}

.profile-info img {
    position: relative;
    background-color: none !important;
    padding: 0.1rem;
    display: block;
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: inherit !important;

}

.container-wrapper {
    top: -3.5rem;
    margin-bottom: -40px;
    border-radius: 50% !important;
}

.avatar-container {
    width: 75px;
    height: 75px;
    border-radius: inherit;
    overflow: hidden;
    box-sizing: border-box;
}

/* Style for the gradient border */ 
.container-wrapper::before { 
    content: ''; 
    position: absolute; 
    width: 75px;
    height: 75px;
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 50% !important;
    z-index: 0; /* Place behind the content */ 
    padding: 2px; /* Space for the inner container */ 
    box-sizing: border-box; /* Ensure the padding and margin are calculated properly */ 
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); 
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); 
    -webkit-mask-composite: destination-out; 
    mask-composite: subtract;
}

.profile-info {
    align-items: flex-start;
}

.profile-info h2 {
    font-size: 1.3rem;
    margin-bottom: 1px;
}

.profile-info h2,  .profile-info p {
    position: relative;
    top: -0.2rem;
    padding-left: 5px;
}

.profile-info p {
    
    color: #aaa;
    margin-bottom: -40px;
    top: -0.7rem !important;
    
}

.profile-card-content-container{
    flex-direction: column;
}

.profile-stats, .edit-button, .socials-button, .follow-button{
    position: relative;
    top: 2rem;
    align-items: flex-start;
}

.profile-stats {
    gap: 1rem !important;
    width: 25%;
    margin-bottom: 15px;
    justify-content: space-evenly;
}

.profile-stats div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-stats div h3 {
    font-size: 1.1rem;
    margin-bottom: 5px;
    cursor: pointer;
}

.profile-stats div p {
    color: #aaa;
    font-size: 0.75rem !important;
}

.profile-card-button-container div {
    width: 50%;
    top: 0.5rem !important;
    position: relative;
}
/* .profile-card-button-container {
    gap: 0.5rem;
} */

.profile-action-button-container {
    gap: 0.5rem;
}

.profile-action-button-container div {
    width: 50%;
    top: -1.3rem !important;
    position: relative;
}

.other-profile-page-body .follow-button p {
    margin-bottom: 0;
}

.user-card-container-box button {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    transition: all 0.3s;
    width: 100%;
    padding: 0.5rem !important;
    font-size: 0.8rem !important;
}

.user-card-container-box .edit-button-label {
    font-size: 0.8rem !important;
    margin: 0;
}

.message-container {
    width: 100% !important;
    margin-top: 0.3rem;
}

.message-button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(50, 49, 48) !important;
}

.user-card-container-box button:hover, .follow-button {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%) !important;
}

.edit-button {
    background-color: rgb(59, 58, 57) !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* -------------------Tabs-------------------------- */
.profiles-tab-container{
    margin-bottom: 4rem;
}
.profiles-tab-container-tabs {
    margin-bottom: 1rem;
}

.profiles-tab-content {
    height: 80vh;
    overflow-y: scroll;
}

.series-card-container {
    background: #010101;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    width: 120px;
    margin-bottom: 1rem;
    flex: 1 1 calc(50% - 2rem); 
    max-width: calc(50% - 2rem); 
    margin: 0 1rem; 
    margin-bottom: 1.5rem;
}

.series-card-container:hover {
    transform: translateY(-5px);
}

.series-card-cover img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    height: 200px;
}

.series-card-info h4 {
    margin: 10px 0 5px;
    font-size: 1rem;
}

.series-card-info span {
    color: #f8dd5b;
    font-size: 0.9rem;
}

.series-card-info p {
    margin: 10px 0 0;
    font-size: 0.85rem;
    color: #bbb;
}

.character-card-container {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 20px 8px 80px 0 #0000000a;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 2em;
    width: fit-content;
}

.characters-content-container .character-card-container {
    display: flex;
    box-shadow: 20px 8px 80px 0px #0000000A;
    background-color: #FFFFFF;
    border-radius: 12px !important;
    width: 100%;
    margin-bottom: 1rem !important;
    overflow: hidden;
}

.profiles-tab-container .character-card-content {
    background-color: #0a0a0a !important;
}

.characters-content-container .character-card-cover {
    width: 100%;
    height: 170px;
    position: relative;
}

.characters-content-container .character-card-cover img {
    width: 100%;
    height: 100%;
}

.characters-content-container .character-card-avatar {
    width: 50px;
    height: 50px;
    margin-left: 10%;
    position: absolute;
    top: 80%;
}

.characters-content-container .character-card-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.characters-content-container .character-card-info {
    padding: 1em 2em 1em 2em;
}

.characters-content-container .character-card-info p {
    margin-top: 1em;
    width: 100%;
}

.character-card-social-container {
    display: flex;
    gap: 1em;
}

.character-card-social {
    width: 30px;
    height: 30px;
}

.character-card-social img {
    width: 100%;
    height: 100%;
}

/* Edit Profile Tab ------------------------------------------------------------ */
.profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid #555;
    margin-bottom: 15px;
}

.creator-profile-form {
    width: 90%;
    background-color: #16161a;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    height: auto;
    margin-bottom: 4rem;
}

.profile-picture-label {
    margin-bottom: -1.5rem !important;
}

.profile-photo-input, .cover-photo-input {
    background-color:rgb(59, 58, 57);
    border-radius: 10px;
    padding: 10px 0px;
}

.img-upload-container{
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.profile-remove-img-button {
    left: 0rem;
    top: 2rem;
    opacity: 1.0 !important;
    z-index: 1;
}

.profile-remove-img-button:hover {
    background-color: #c82333 !important;
    background: #c82333 !important;
}

.img-upload-icon{
    height: 40px;
    width: 40px;
    background-color: white;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
}

.img-upload-container b, .img-upload-icon {
    position: absolute;
}

.img-upload-container b {
    top: 7rem;
}

.img-upload-icon h1{
    font-size: 2rem;
    position: relative;
    color: #5e52a1;
    top: 5px;
}

.profile-photo-input.with-image{
    opacity: 0.5;
    object-fit: cover;
    width: 100%;
    height: 100px;
}

.profile-photo-input .creator_image_upload_container {
    height: 80px;
    width: 80px;
    margin-bottom: -18px;
    opacity: 0.7;
}

.creator-profile-form input[type="text"], 
.creator-profile-form textarea, .creator-profile-form .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #444 !important;
    border-radius: 7px;
    background-color: #2f2f32 !important;
    background: #2f2f32 !important;
    color: #fff;
    font-size: 0.8rem;
}

.cover-photo-label {
    margin-bottom: -0.75rem !important;
}

.cover-photo-input .cover-photo-input-container {
    background-color: #272727;
    border-radius: inherit;
    align-items: center;
    width: 95%;
    height: 95%;
    padding: 10px 0px;
    border: 1px dashed #5e52a1 ;
}

.creator-profile-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #ffffff;
}

.creator-profile-form button {
    border: none;
    background-color: #444;
    color: #fff;
    cursor: pointer;
    text-align: center;
    border-radius: 10px;
    transition: all 0.3s;
    font-size: 0.8rem !important;
}

.creator-profile-form button:hover {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%) !important;
}

.add-social-button {
    background-color: #2f2f32 !important;
    margin: 2px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 0.7rem !important;
}

.add-social-button-container, .save-button-container {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 10px;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box !important;
}

.action-button-container {
    border-radius: 10px;
}

.action-button-container .save, .action-button-container .back{
    background-color: #272727 !important;
    padding: 0.6rem !important;
}

.action-button-container .save {
    margin: 2px;
    object-fit: fill !important;
}

@media (min-width: 425px){
    .series-card-container {
        flex: 1 1 calc(33.33% - 2rem); 
        max-width: calc(33.33% - 2rem);
    }
}

@media (min-width: 768px) {
    /* .view-creator-profile p {
        font-size: 1rem !important;
    } */

    .profile-card {
        height: auto;
    }

    .cover-photo {
        height: 150px;
    }

    .profile-card {
        flex-direction: row;
    }

    .profile-card p {
        font-size: 0.9rem !important;
    }

    /* to limit the growth of user description */
    /* .profile-info {
        max-width: 170px !important;
    } */
    .profile-info {
        flex-grow: 1;
        align-items: flex-start;
    }

    .profile-info img {
        width: 150px;
        height: 150px;
        padding: 5px;
        margin-bottom: 2.5px;
    }

    .container-wrapper {
        top: -5.5rem;
        margin-bottom: -40px;
    }
    
    .avatar-container {
        width: 155px;
        height: 155px;
    }
    
    .container-wrapper::before { 
        width: 155px;
        height: 155px;
        padding: 2px; 
    }
    
    .profile-info h2 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    .profile-info h2, .profile-info p {
        top: -1.8rem !important;
        padding-left: 10px;
    }
    
    .profile-info p {
        margin-bottom: 0;
        margin-top: -0.5rem;
    }

    .profile-page-body .profile-card-button-container div {
        top: -0.8rem !important;
    }

    .profile-page-body .profile-card-content-container{
        flex-direction: row;
        justify-content: flex-end;
    }

    .other-profile-page-body .profile-card-content-container{
        flex-direction: column;
        justify-content: flex-end;
        width: auto !important;
    }

    /* .profile-page-body .profile-card-button-container {
        align-items: flex-start;
    } */

    /* .profile-page-body .profile-action-button-container {
        align-items: flex-start;
    } */
    
    .other-profile-page-body .profile-card-button-container{
        flex-direction: row !important;
        gap: 0;
        /* justify-content: flex-end;
        width: auto !important; */
    }

    .follow-button p {
        font-size: 1rem !important;
    }

    .message-container button {
        margin-bottom: 0 !important;
        width: auto;
    }

    .message-container {
        width: auto !important;
        padding: 5.5px;
    }

    .other-profile-page-body .profile-action-button-container button {
        padding: 0.7rem 2rem !important;
    }


    .profile-stats, .edit-button, .socials-button{
        top: 2rem;
        align-items: flex-start;
    }
    
    .profile-page-body .profile-stats {
        margin: 0 2.5rem 0 1.5rem;
        position: relative;
        top: 5.5rem !important;
        justify-content: flex-end;
        gap: 1.8rem !important;
        width: 50% !important;
    }
    
    /* .other-profile-page-body .profile-stats {
        margin: 0 2.5rem 0 1.5rem;
        position: relative;
        top: 5.5rem !important;
        justify-content: flex-end;
        gap: 1.8rem !important;
        width: 50% !important;
    } */

    .profile-stats div {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 60px;
    
    }
    
    .profile-stats div h3 {
        font-size: 1.3rem !important;
        margin-bottom: 5px;
    }
    
    .profile-stats div p {
        font-size: 0.9rem !important;
    }

    .profile-card-button-container {
        width: auto !important;
    }

    .profile-card-button-container div {
        width: auto;
        position: relative;
    }

    .user-card-container-box button {

        border-radius: 10px;
        transition: all 0.3s;
        padding: 0.5rem !important;
        font-size: 0.8rem !important;
    }
    
    .user-card-container-box .edit-button-label {
        display: none;
    }
    
    .edit-button {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 50% !important;
    }

    .profile-page-body .profile-card-button-container {
        gap: 0.5rem;
        top: 5rem !important;
        position: relative;
    }

    .other-profile-page-body .profile-card-button-container {
        top: 0 !important;
    }
    
    .user-card-container-box button {
        font-size: 1rem !important;
    }

    /* ----------------- Tabs --------------- */
    .view-creator-profile .profiles-tab-content {
        justify-content: center !important;
        text-align: center !important;
    }

    .series-tab-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start !important;
    }

    /* .series-card-container {
        height: auto;
        width: 150px;
        margin-bottom: 1rem;
    } */

    .series-card-container {
        flex: 1 1 calc(25% - 2rem); /* Set width for 4 items per row */
        max-width: calc(25% - 2rem); /* Prevent overflow */
        margin: 0 1rem; /* Remove default margin */
        margin-bottom: 1.5rem;
    }

    .series-card-info h4 {
        font-size: 1.2rem;
    }

    .series-card-info span {
        font-size: 1rem;
    }

    .series-card-info p {
        font-size: 0.9rem;
    }

    .profiles-tab-content .characters-content-container .character-card-container {
        flex: 1 1 calc(50% - 2rem) !important; 
        max-width: calc(50% - 2rem) !important; 
        margin: 0 1rem !important;
        margin-bottom: 1.5rem !important;
        background: #010101 !important;
    }

    /* ------------------------- Edit Profile ------------------- */

    .profile-remove-img-button {
        top: 2.5rem;
    }

    .img-upload-container, .profile-photo-input, .cover-photo-input .cover-photo-input-container {
        height: 170px !important;
        justify-content: center;
    }

    .profile-photo-input.with-image {
        background-size: cover;
        height: 100%;
    }

    .img-upload-container.with-image b {
        top: 11rem;
    }

    .profile-photo-input.no-image b {
        position: relative;
        bottom: -1.1rem;
    }

    .cover-photo-input-container b {
        position: relative;
        bottom: -2rem;
    }

    .creator-profile-form input[type="text"], 
    .creator-profile-form textarea, .creator-profile-form .form-control {
        border-radius: 10px;
        font-size: 1.2rem;
    }

    .creator-profile-form button {
        cursor: pointer;
        transition: all 0.3s;
        font-size: 1.2rem !important;
    }
}

@media (min-width: 960px) {
    .profile-page-body, .other-profile-page-body {
        align-items: center;
    }

    .profile-page-body .user-card-container-box, .profile-page-body .profiles-tab-container, .profile-page-body .creator-profile-form {
        width: calc(960px - 2rem) !important;
        margin: 0 1rem;
    }

    .profile-page-body .user-card-container-box, .profile-page-body .creator-profile-form  {
        margin-top: 1rem !important;
        border-radius: 10px !important;
        overflow: hidden;
    }

    .other-profile-page-body .user-card-container-box, .other-profile-page-body .profiles-tab-container {
        width: calc(960px - 2rem) !important;
        margin: 0 1rem;
    }

    .other-profile-page-body .user-card-container-box {
        margin-top: 1rem !important;
        border-radius: 10px !important;
        overflow: hidden;
    }
}

@media (min-width: 1024px) {

    /* .view-creator-profile .profile-container {
        flex-direction: row !important;
        align-items: stretch !important;
    } */

    .profile-sidebar {
        height: auto !important; 
        flex-grow: 1;
        /* top: -9rem; */
        background-color: #101012;
        z-index: 1;
        display: flex !important;
        flex-direction: column;
        align-items: stretch;
        /* box-sizing: unset; */
    }

    .profile-sidebar .profile-sidebar-content {
        height: 100% !important;
        width: 250px;
        position: relative;
        flex-grow: 1;
        margin-bottom: 18rem;
        background-color: #101012;
        top: -5.5rem;
    }
    
    /* Profile section styling */
    .profile-section {
        background-color: #313035;
        border-radius: 10px;
        position: relative;
        margin-top: 2rem;
        cursor: pointer;
    }
    
    .sidebar-content {
        width: 260px !important;
    }
    
    .profile-image {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 8px;
    }
    
    .profile-section hr {
        margin: 3px !important;
        height: 3px;
        background-color: white;
        border-radius: 10px;
    }
    
    .profile-section h5 {
        font-size: 1.25rem;
    }
    
    .profile-sidebar small {
        display: block;
        font-size: 0.7rem;
    }
    
    .profile-section span {
        font-size: 0.8rem;
    }
    
    /* Navigation styling */
    .profile-sidebar .nav {
        list-style-type: none;
        padding: 0;
        width: 100%;
    }
    
    .profile-sidebar .nav-item {
        width: 100%;
    }

    .profile-sidebar .nav-item i {
        padding: 3px 10px 7px 10px;
        border-radius: 6px;
        background-color: #c82333;
        /* font-size: 0.8rem; */
    }

    .profile-sidebar .nav-item .bi-wallet {
        background-color: #360209;
    }

    .profile-sidebar .nav-item .bi-gear {
        background-color: #030539;
    }

    .profile-sidebar .nav-item .bi-box-arrow-right {
        background-color: #3D2D04;
    }
    
    .profile-sidebar .nav-link {
        color: white;
        text-decoration: none;
        padding: 1rem 0.75rem 1rem 0.8rem;
        display: flex;
        align-items: center;
        width: 100%;
    }
    
    .profile-sidebar .nav-link:hover {
        background-color: #495057;
        border-radius: 0.25rem;
    }
    
    .profile-sidebar .icon {
        margin-right: 0.75rem;
    }
    
    .plan-container {
        background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
        border-radius: 10px !important;
        margin-top: 3px !important;
    }
    
    .plan-container div {
        background: #343a40;
        border-radius: 10px !important;
        margin: 1px;
        padding: 0 5px 2px 5px;
    }
    
    .upper-subsection .user-det {
        margin-left: 8px;
    }
    
    .user-det {
        align-items: flex-start;
    }
    
    .user-det h7{
        font-size: 0.8rem;
    }

    .profile-page-body .user-card-container-box, .profile-page-body .profiles-tab-container, .profile-page-body .creator-profile-form {
        width: calc(774px - 2rem) !important;
    }

    .other-profile-page-body .user-card-container-box, .other-profile-page-body .profiles-tab-container {
        width: calc(1024 - 2rem) !important;
    }

}

@media (min-width: 1150px) {
    .profile-page-body .user-card-container-box, .profile-page-body .profiles-tab-container, .profile-page-body .creator-profile-form {
        width: calc(900px - 2rem) !important;
    }

    .other-profile-page-body .user-card-container-box, .other-profile-page-body .profiles-tab-container {
        width: calc(1150px - 2rem) !important;
    }

    .other-profile-page-body .profile-action-button-container button {
        padding: 0.7rem 3rem !important;
    }

    .other-profile-page-body .profile-action-button-container {
        gap: 10.5px;
    }
    .other-profile-page-body .profile-card-button-container {
        gap: 4px;
    }
}

@media (min-width: 1275px) {
    .profile-page-body .user-card-container-box, .profile-page-body .profiles-tab-container, .profile-page-body .creator-profile-form  {
        width: calc(925px - 2rem) !important;
    }

    .other-profile-page-body .user-card-container-box, .other-profile-page-body .profiles-tab-container {
        width: calc(1250px - 2rem - 259px) !important;
    }
}

@media (min-width: 1440px) {
    .profile-page-body .user-card-container-box, .profile-page-body .profiles-tab-container, .profile-page-body .creator-profile-form  {
        width: calc(1040px - 2rem) !important;
    }

    .other-profile-page-body .user-card-container-box, .other-profile-page-body .profiles-tab-container {
        width: calc(1400px - 2rem - 259px) !important;
    }
    
    .profile-image {
        width: 80px;
        height: 80px;
        border-radius: 10px;
    }
    
    .profile-section h5 {
        font-size: 1.6rem;
    }
    
    .profile-sidebar small {
        font-size: 0.9rem;
    }
    
    .profile-section span {
        font-size: 0.9rem;
    }
    
    .user-det h7{
        font-size: 0.9rem;
    }

    .profile-sidebar, .profile-sidebar .profile-sidebar-content {
        width: 300px !important;
    }

    .series-card-container {
        flex: 1 1 calc(20% - 2rem); /* Set width for 4 items per row */
        max-width: calc(20% - 2rem); /* Prevent overflow */
    }

    .profiles-tab-content .characters-content-container .character-card-container {
        flex: 1 1 calc(33.33% - 2rem) !important; 
        max-width: calc(33.33% - 2rem) !important; 
    }

    .profile-info h2 {
        font-size: 1.8rem;
    }
    
    .profile-stats div {
        min-width: 70px;
    }
    
    .profile-stats div h3 {
        font-size: 1.6rem !important;
    }
}

@media (min-width: 1750px) {
    .profile-page-body .user-card-container-box, .profile-page-body .profiles-tab-container, .profile-page-body .creator-profile-form  {
        width: calc(1200px - 2rem) !important;
    }

    .other-profile-page-body .user-card-container-box, .other-profile-page-body .profiles-tab-container {
        width: calc(1400px - 2rem) !important;
    }

    .profile-sidebar, .profile-sidebar .profile-sidebar-content {
        width: 350px !important;
    }
}

@media (min-width: 2000px) {
    .profile-page-body .user-card-container-box, .profile-page-body .profiles-tab-container, .profile-page-body .creator-profile-form  {
        width: calc(1450px - 2rem) !important;
    }

    .other-profile-page-body .user-card-container-box, .other-profile-page-body .profiles-tab-container {
        width: calc(1650px - 2rem) !important;
    }

    .profile-sidebar, .profile-sidebar .profile-sidebar-content {
        width: 350px !important;
    }
}

.ReactModal__Content {
    z-index: 2 !important;
    background-color: #010101 !important;
}