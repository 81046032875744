.leaderboard-item-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #D1D4FF1A;
    padding: 12px;
    width: 100%;
    font-size: 12px;
    border-radius: 10px; 
}

.leaderboard-item-avatar-container{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
    margin-left: 8px;
}

.leaderboard-item-avatar{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.leaderboard-item-points{
    font-size: 12px;
    color: white;
    background-color: #FFFFFF1A;
    padding: 2px 8px;
    border-radius: 12px;
    width: 70px;
    text-align: center;
}

.rewards-item-container-one{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #D1D4FF26;
    border-radius: 10px;
    padding: 12px 16px;
    font-size: 12px;
}

.rewards-item-container-two{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #D1D4FF1A;
    border-radius: 10px;
    padding: 12px 16px;
    font-size: 12px;
}

.trophy-icon{
    width: 30px;
    height: 30px;
    color: #F7DE5E;
}

.diamond-icon{
    width: 20px;
    height: 20px;
}

.period-icon{
    width: 5px;
    height: 5px;
    background-color: #F7DE5E;
    border-radius: 50%;
}

.leaderboard-display-container{
    overflow: hidden;
    padding: 20px;
}

.rewards-container{
    width: 80%;
}

.leaderboard-category-button{
    all: unset;
    margin-right: 10px;
    color: #A1A1A4; 
    font-size: 20px;
}

.leaderboard-category-button-selected{
    all: unset;
    margin-right: 10px;
    color: white;
    font-size: 20px;
}