.main .main-nav {
    /* padding: 2em 0; */
    background-color: #0B0C0E;
    height: 55px;

    padding: 0 !important;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
}

.main .main-nav .container-xxl {
    height: 70%;
    /* overflow: hidden; */
    flex-direction: row;
    justify-content: flex-end !important;
}

/* @media only screen and (max-width: 767px) {
    .main-nav {
        padding: 0.6em 0;
    }
  } */

.main .main-nav .container-xxl .search-field-container {
    height: 100%;
    align-items: center;
}

.main .main-nav .container-xxl .search-field {
    color: #fff;
    background-color: #1E1F26;
    width: 10rem;
    border-radius: 20px;
    height: 90%;
    border: none;
    font-size: 0.75rem;
    padding: 0 15px 0 10px;
    display: flex;
    justify-content: center;
    
}

.main .main-nav .container-xxl .order-1 {
    height: 100%;
    margin: 0 !important;
    padding-right: 0 !important;
    width: auto !important;
}

.main .main-nav .container-xxl .order-1 .dropdown {
    width: auto !important;
}

.main .main-nav .container-xxl .order-1 .user-profile .nav-link {
    margin: 0 !important;
    height: auto !important;
    width: auto !important;
}

.container-xxl .order-1 .user-profile .nav-link .avatar {
    height: 30px !important;
    width: 30px !important;
    min-width: 30px !important;
    min-height: none !important;
    padding: 0 !important;
}

@media (min-width: 425px) {
    .main .main-nav .container-xxl {
        flex-wrap: nowrap;
        width: 100% !important;
    }

    .main .main-nav .container-xxl .search-field {
        width: 15rem;
    }
}

@media (min-width: 768px) {
    .main .main-nav .container-xxl .search-field {
        font-size: 0.85rem;
        border-radius: 50px;
        padding: 0 12px;
    }
    .container-xxl .order-1 .user-profile .nav-link .avatar {
        height: 40px !important;
        width: 40px !important;
    }

    .container-xxl .order-1 .language-button-container .language-button-icon img {
        height: 30px !important;
        width: 30px !important;
    }
}

@media (min-width: 1024px) {
    /* .main .main-nav {
        height: 60px;
    } */

    .main .main-nav .container-xxl {
        height: 80%;
    }
    .main .main-nav .container-xxl .search-field {
        width: 20rem;
    }

}

@media (min-width: 1440px) {
    .main .main-nav .container-xxl {
        height: 85%;
    }
    .main .main-nav .container-xxl .search-field {
        width: 25rem;
    }
}