/*
* Admin Layout (cryptoon)
* @author: Pixelwibes
* @design by: Pixelwibes.
* @event-namespace:cryptoon
* Copyright 2022 Pixelwibes
*/

// Chat app page scss
.card-chat-container {
    display: flex;
    flex-grow: 1;
    min-width: 0;
    width: 100%;
    font-size: 17;
    // background: linear-gradient(25deg, rgba(10,10,10,0) 0%, rgba(5,5,5,0.51) 51%, rgb(0, 0, 0) 86%)!important;
}

@media only screen and (max-width: 767px) {
    .card-chat-container {
        background: #311B50!important;
    }
}

.card-chat{
    background: linear-gradient(25deg, rgba(10,10,10,1) 0%, rgba(5,5,5,0.51) 51%, rgb(0, 0, 0) 100%)!important;
}

.card-chat-body{
    height: 100vh;
    width: 100%;
}

// left sidebar chat list, groups and contact
.chatlist-toggle{
    color: var(--primary-color);
}

.go-back-btn{
    background: rgba(0,0,0,0.5);
    border-radius: 15px;
}

.chat-header{
    background-color: rgba($color: #00000080, $alpha: 0.1);
}

.text-input-container{
    background-color: rgba($color: #4D4D4D80, $alpha: 1);
}

.gradient-border {
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2px solid transparent;
        background: linear-gradient(115deg, rgba(255,115,29,1) 16%, rgba(13,215,227,1) 63%, rgb(139, 138, 138) 100%);
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
}

.chat-sent-by-user {
    background: #A8A8A8;
    // background: linear-gradient(25deg, rgba(255, 119, 119, 1) 0%, rgba(255, 115, 29, 1) 100%);
    border-radius: 20px 20px 5px 20px;
}

.chat-received-by-user {
    background: #272727;
    // background: linear-gradient(94deg, rgba(43, 110, 209, 1) 0%, rgba(6, 37, 80, 1) 100%);
    border-radius: 5px 20px 20px 20px;
}

.card-chat {
    min-width: 25%;
    max-width: 25%;
    overflow: hidden;
    background-color: rgba(#000000, .5)!important;

    @media only screen and (max-width: #{$break-medium - 1px}) {
        @include transition(ease .1s);
        min-width: 80%;
        max-width: 80%;
        position: fixed;
        left: -100%;
        z-index: 9;
        &.open {
            left: 0;
        }
    }

    @media only screen and (max-width: #{$break-small - 1px}) {
        &.open {
            min-width: 100%;
            max-width: 100%;
            background-color: rgba(#311B50, 1)!important;
        }
    }

    @media only screen and (max-width: 360px) {
        &.open {
            min-width: 100%;
            max-width: 100%;
        }
    }

    .tab-content {
        height: 73vh;
        overflow: auto;
    }

    .list-group {
        .list-group-item {
            &.open,
            &:hover {
                background-color: var(--body-color);
            }
            a {
                color: var(--color-500);
            }
        }

        .msg-time {
            font-size: 11px;
            font-weight: 600;
        }
    }

    .nav-link {
        @media only screen and (max-width: #{$break-small - 1px}) {
            padding: 10px;
        }
    }
}


// 
.card-chat-body{

    @media only screen and (max-width: #{$break-small - 1px}) {
        padding-bottom: 30px;
    }

    .max-width-70{
        max-width: 70%;

        @media only screen and (max-width: $break-small) {
            max-width: 100%;
        }
    }

    .chat-history {
        @include overflow(scroll);
        height: 100vh;
        overflow-y: auto;
        padding: 1rem 2rem !important;
        @media only screen and (max-width: #{$break-small - 1px}) {
            padding: 0.5rem 1rem!important;
        }
    }

    .chat-message{
        position: relative;
        .btn.btn-dark{
            position: absolute;
            right: 10px;
            top: 14px;
        }
        .action-bar{
            position: absolute;
            right: 10px;
            top: 10px;
        }
        .form-control{
            padding-right: 80px;
        }
    }

    .text-right{
        .message{
            color: $white;
        }    
    }
    .message{
        color: var(--color-600);
    }

    &.card{
        background-color: transparent;
    }
}

.chat-menu-icon-btn{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #272727;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #f0f0f0;
    }

    @media (max-width: 767px) {
        width: 30px;
        height: 30px;
    }

    @media (max-width: 480px) {
        width: 25px;
        height: 25px;
    }

}

.reminder-container{
    background: #000000;
}

.chat-inputs-container{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.chat-input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.chat-form-control {
    background-color: #272727;
    color: #FFFFFF !important;
    border-radius: 50px;
    width: 100%;
    padding: 15px 50px 15px 15px;
    resize: none;
    height: 60px;
    box-sizing: border-box;
    font-size: 16px;
    outline: none;
    font-weight: bold;

    @media (max-width: 767px) {
        font-size: 14px;
        height: 50px;
        padding: 10px 40px 10px 10px;
    }

    @media (max-width: 480px) {
        font-size: 13px;
        height: 45px;
    }
}

.chat-form-control::placeholder {
    color: #8A8A8F;
    font-weight: bold;
    opacity: 1;
}
.gradient-chat-menu-icon-btn {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: linear-gradient(
        to bottom left,
        rgba(242, 92, 105, 1),
        rgba(237, 91, 9, 0.95),
        rgb(177, 46, 90),
        rgba(56, 17, 140, 1)
    );
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;

    @media (max-width: 767px) {
        width: 30px;
        height: 30px;
    }

    @media (max-width: 480px) {
        width: 25px;
        height: 25px;
    }
}

.gradient-chat-menu-icon {
    color: #FFFFFF;
    font-size: 15px;
}

.chat-menu-icon {
    color: #FFFFFF;
    font-size: 15px;
}

.gradient-chat-menu-icon-btn:hover {
    background-color: #f0f0f0;
}

.menu-container.visible {
    max-height: 370px;
    padding: 1rem;
    visibility: visible;
    overflow: visible;
}
.menu-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    background-color: rgba(1,1,1,1);
    overflow: hidden;
}

.menu-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    width: 80px;
    text-align: center;
}

.menu-icon-btn{
    background-color: rgba(235, 235, 235, 0.2);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-icon-image {
    width: 24px;
    height: 24px;
}

@media (min-width: 600px) {
    .menu-icon-container {
        width: 70px;
    }
}

@media (min-width: 768px) {
    .menu-icon-container {
        width: 90px;
    }
}

@media (min-width: 1024px) {
    .menu-icon-container {
        width: 100px;
    }
}