.chat-sidebar {
    background-color: #16171A;
    width: 400px;
}

@media (max-width: 768px){
    .chat-sidebar{
        display: none;
    }
}

.chat-sidebar-close{
    width: 75px;
}

.chat-sidebar-close span{
    display: none;
}

.chat-sidebar-back-btn{
    all: unset;
    background-color: #D1D4FF3D;
    padding: 5px 10px 5px 10px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 10px 10px;
}

.chat-sidebar-hidden-back-btn{
}

.chat-sidebar-options-icon{
    width: 15px;
    height: 15px;
}

.chat-sidebar-options-container{
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.chat-sidebar-options{
    all: unset;
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 10px;
    padding: 1px;
    width: 100%;
}

.chat-sidebar-options-content{
    display: block;
    background-color: #272727;
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-size: small;
    text-align: center;
}

.chat-sidebar-options-persona-image{
    background-color: #D1D4FF3D;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    object-fit: cover;
}