.connection-info img {
    width: 50px;
    height: 50px;
}

.social-media-container {
    margin-bottom: 5rem;
}

.social-media-container .connections {
    width: 100%;
    padding: 0 1rem;
}

.social-media-container h2 {
    padding: 2rem 1rem 0 1rem;
    width: 100%;
    /* background-color: rgb(39, 39, 39); */
}

.connection-item {
    display: flex;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
    cursor: pointer;
}

.connection-info {
    display: flex;
    flex-wrap: wrap;
}

.social-title-label {
    font-size: 1rem;
    margin-bottom: 0;
}

.social-link-link {
    margin-top: 0.5rem;
    font-size: 0.6rem;
    margin-bottom: 0;
}

.social-media-container .connection-item {
    padding: 0.8rem;
    border: 1px solid #fff;
    border-radius: 10px;
}

.connection-header {
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.connection-header p {
    margin-bottom: 0;
}

.user-header {
    font-size: 1rem;
    font-weight: 700;
}

.ReactModal__Content {
    z-index: 1000 !important;
    background-color: #fff;
}

.ReactModal__Content .social-links-item-container .socials-body {
    align-items: center;
    gap: 10px;
}

.ReactModal__Content .socials-modal-body {
    align-items: center;
}

.socials-header {
    /* margin-top: 10px; */
    width: 224px !important;
    padding: 5px;
    /* background-color: #16161a; */
    background: #010101;
    border-radius: 5px;
    box-sizing: content-box;
    /* position: relative; */
    margin-bottom: -0.5rem;
    overflow: hidden;
    
}

/* .socials-header::before {
    position: relative;

    margin-top: 10px;
    padding: 3px;
    background-color: #16161a;
    background: #010101;
    border-radius: 5px;
} */

.socials-header h6{
    /* position: relative; */
    width: 110px;
    margin-bottom: 0;
    /* margin-bottom: -0.5rem; */
}

.filled-socials-container {
    background-color: #16161a;
    background: #16161a;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
}

.filled-socials-container .schedule-messages-container {
    background-color: #16161a;
    background: #16161a;
}

.social-links-item-container {
    gap: 1rem !important;
    background-color: #16161a !important;
    background: #16161a;
}

.socials-body {
    align-items: center;
}

/* this is supposed to make the input field wider when you click it */
.socials-body input:focus {
    width: 100% !important;
}

.socials-body .trash-icon-container {
    background-color: transparent;
    padding: 0;
    margin-top: 0 !important;
}

.socials-body .trash-icon-container:hover {
    opacity: 0.7;
}

.socials-body .trash-icon-container .trash-icon-button {
    color: #ff2e00;
    font-size: 1.2rem;
}

.socials-body input, .add-social-links-item-container input {
    padding: 8px !important;
    font-size: 0.8rem !important;
    border: 1px solid #444 !important;
    border-radius: 5px !important;
    background: #010101 !important;
}

.socials-body .form-label {
    font-size: 0.9rem !important;
}

.social-link-link-label {
    margin-bottom: 3px;
}

.social-modal-button-container {
    width: 100%;
}

.social-modal-button-container .save-button {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%) !important;
    border-radius: 10px;
}

.social-modal-button-container .cancel-button {
    background: #636363;
    border-radius: 10px;
}

.social-modal-button-container .cancel-button:hover {
    opacity: 0.6;
    background: #636363;
}

.social-modal-button-container .save-button:hover {
    opacity: 0.6;
}

.add-social-links-item-container .schedule-messages-container {
    width: 100%;
    background-color: #16161a;
    background: #16161a;
}

.filled-socials-container .schedule-messages-container .socials-body {
    width: 261px;
}

.filled-socials-container .schedule-messages-container {
    border-radius: 5px;
}

.add-social-links-item-container {
    width: 100%;
    align-items: center;
    background-color: #16161a;
    background: #16161a;
    border-radius: 10px;
    padding: 10px;
}

.add-social-links-item-container input {
    background-color: #292930 !important;
    background: #292930 !important;
}
/* 
.socials-modal-bottom-section {
    position: absolute;
    bottom: -2rem;
    padding: 28px;
    z-index: 1000;
    margin-bottom: 1.5rem;
} */

.profile-stat-modal .close-button {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 2rem;
}

.profile-stat-modal .modal-header h2 {
    margin-bottom: 0;
}

.profile-stat-modal .modal-header {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.profile-stat-modal .tab-container {
    padding: 0.8rem;
    gap: 10px;
    background-color: #171726;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.loading-placeholder, .modal-no-content {
    font-weight: 700;
    opacity: 0.8;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-stat-modal .tab-container .tab.active {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%) !important;
    padding: 1px;
}

.profile-stat-modal .tab-container .tab.active .button-content {
    background: #222230;
    height: 100%;
    border-radius: 6px;
    padding: 0.5rem;
}

.profile-stat-modal .follower-list {
    display: flex;
    align-items: center;
}

.profile-stat-modal .tab-container button {
    width: 50%;
    padding: 0.5rem;
    font-size: 0.9rem;
    border: none;
    border-radius: 6px;
    color: #fff;
    background-color: #3d3d50;
}

.profile-stat-modal .tab-container button:hover {
    translate: (0.5);
    opacity: 0.6;
}

.profile-stat-modal .follower-item {
    justify-content: space-between;
    background-color: #2c2c33;
    padding: 0.5rem;
    border-radius: 10px;
    align-items: center;
}

.profile-stat-modal h3 {
    padding-left: 0.5rem;
}

.follower-avatar-container {
    border-radius: 50%;
    background-color: #fff;
    overflow: hidden;
}

.follower-username {
    margin-bottom: 0;
}

.follower-info {
    align-items: center;
}

.follower-follow-button-container {
    background-color: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%) !important;
    border-radius: 5px;
    padding: 1px;
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%) !important;
    
}

.follower-follow-button-container button {
    background-color: black;
    color: #fff;
    border-radius: 5px;
    border: none;
    margin: 1px;
}