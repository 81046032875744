:root {
    --theme-color: #ff721d;
}

.search-container {
    display: flex;
    flex-direction: row;
    margin: 1em;
    width: 100%;
    padding-right: 2%;
}

#category-input {
    width: 30%;
}

.bg-selected {
    background-color: var(--theme-color);
    color: white!important;
}

.create-deal-profile-btn-container {
    display: flex;
    flex-direction: row;
    gap: 2em;
    padding: 1em;
}

.br-50 {
    border-radius: 50%!important;
}

.jc-c {
    display: flex;
    justify-content: center;
}

/* redesign styles */

.top-bar-container {
    border: 1px solid  #E5E5E5;
    background-color: #f1eded!important;
    border-radius: 12px;
}

.tab-toggler {
    height: 7vh;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 0;
    color: #B7C1D3;
}

.create-deal-profile-btn-container button {
    border: 1px solid var(--theme-color);
    background-color: transparent!important;
    color: var(--theme-color)!important;
}

/* redesign view creator profile */

.view-creator-profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1em;
    gap: 1em;
    align-items: flex-start;
}

.user-card-container{
    background: rgba(0, 0, 0, 0.5);
    height: 90vh;
}

.user-card-container-box{
    background: radial-gradient(circle, rgba(0,0,0,0.9) 30%, rgba(41, 42, 42, 0.6) 100%);
    border-radius: 12px;
    /* min-height: 350px; */
    max-width: 300px;
    box-shadow: 10px 12px 14px rgba(0, 0, 0, 0.15);
}

.profiles-tab-container-tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 1;
    color: #B7C1D3;
}
.profiles-tab-container {
    width: 100%;
    margin-bottom: 1;
    color: #B7C1D3;
}

.profiles-tab {
    padding: 0.6em;
    cursor: pointer;
    width: 33%;
    text-align: center;
    border-bottom: 1px solid #B7C1D3;
}

.profiles-selected-tab {
    color: var(--theme-color);
    border-bottom: 2px solid var(--theme-color);
}

.add-youtube-button {
    background-color: var(--theme-color) !important;
    width: 98% !important;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin: 20px 5px;
}

.youtube-tab-bar{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0em 6em;
    width: 100%;
    align-self: center;
    
}


.profiles-tab-content {
    height: 70vh;
    padding: 0em 2em;
    justify-content: center;
}

/* create creator profile redesigned */

.secondary-btn {
    background-color: #EBEDF1!important;
    color: black!important;
}

#addlink-btn {
    background-color: #EBEDF1!important;
    color: black!important;
}

.action-btn {
    background-color: var(--theme-color)!important;
    border: 0px!important;
    color: white!important;
    padding: 0.7em;
    border-radius: 3px;
}

.action-outline-btn {
    background-color: transparent!important;
    border: 1px solid var(--theme-color)!important;
    color: var(--theme-color)!important;
    padding: 0.7em;
    border-radius: 3px;
}

.creator_image_upload_container {
    font-family: IBM Plex Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9e9e9;
    height: 140px;
    width: 140px;
    cursor: pointer;
}

.creator-services-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

/* create service form modal */
.create-service-modal-container {
    padding: 2em;
}

.wt-500 {
    font-weight: 500!important;
}

.dotted-border {
    border: 1px dashed #9E9E9E!important;
}

@media (min-width: 118px) {
    .user-card-container {
        display: none;
    }
}
