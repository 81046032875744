.slider-container {
    width: 350px;
    height: 100vh;
    overflow: hidden;
    border-radius: 12px;
    z-index: 0;
    flex-grow: 1;
}

.slider-background-img {
    width: 100% !important;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
}

.messages-container {
    position: absolute;
    z-index: 10;
    top: 0;
    height: 100dvh;
    overflow-y: auto;
    padding: 10px 10px 100px 10px;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
}

.messages-container::-webkit-scrollbar {
    width: 8px;
}

.messages-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.messages-container::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

.slider-bottom-container{
    z-index: 20;
    padding: 10px 10px 20px 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
}

.slider-hashtag{
    color: #05A6D3;
    font-size: small;
}

.chat-with-character-btn{
    all: unset;
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 10px;
    padding: 1px;
    width: 75%;
}

.chat-with-character-btn-content{
    display: block;
    background-color: #272727;
    border-radius: 10px;
    padding: 6px;
    color: white;
    font-size: small;
    text-align: center;
}

.msg-content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
}

.msg-content-action{
    font-style: italic;
    color: #A2A59B;
    margin-bottom: 2px;
}

.user-message{
    flex-direction: column;
    justify-content: flex-end;
    background-color: #272727be;
    border-radius: 14px 0px 14px 14px;
    padding: 10px;
    font-size: 10px;
}

.ai-message{
    flex-direction: column;
    justify-content: flex-start;
    background-color: #272727;
    border-radius: 0px 14px 14px 14px;
    padding: 10px;
    font-size: 10px;
}

.feed-options{
    all: unset;
    background-color: #FFFFFF1A;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.more-options{
    position: absolute;
    bottom: 10px;
    z-index: 50;
    right: -60px;
    gap: 1rem;
}

.user-profile{
    all: unset;
}

.defaultImageBg{
    z-index: -1;
    position: absolute;
}

.messages-container-wrapper{
    background-color: #05A6D3;
    width: 100%;
}

@media (max-width: 768px) {
    .slider-background-img {
        width: 100%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .slider-background-img {
        width: 100%;
    }
}

@media (min-width: 1025px) {
    .slider-background-img {
        width: 100%;
    }
}

@media (max-width: 766px) {
    .slider-background-img {
        height: 95dvh;
    }
    .slider-container {
        margin-bottom: 50px;
    }    
}