.message-sender-name {
    color: gray;
}

.character-price {
    background-color: transparent;
}
.character-price .form-control, .character-price .form-select{
    background: 
    linear-gradient(var(--dark-purple) 0 0) padding-box,
    linear-gradient(25deg, rgba(255, 119, 119, 1) 0%, rgba(255, 115, 29, 1) 100%) border-box;
    padding: 10px;
    border: 2px solid transparent;
    border-radius: 15px;
    display: inline-block;
}

.public-chat-item {
    cursor: pointer;
    width: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.public-chat-item:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ai-character-avatar {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.ai-character-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #ff731d;
}

.ai-character-avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #ff731d;
}

.ai-character-card-container {
    margin-top: 2em;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    box-shadow: 20px 8px 80px 0px #0000000a;
    /* background-color: #ffffff; */
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    width: fit-content;
    gap: 2em;
    width: 340px;
    cursor: pointer;
    padding: 1em;
}

.ai-character-info {
    text-align: center;
}

.ai-character-card-p {
    max-height: 30vh;
    overflow: hidden;
}

.ai-profiles-tab {
    width: 50%;
}

.public-chat-avatar {
    border: 1.2px solid #ff731d !important;
}

.public-message-date {
    font-size: x-small;
}

.message-reaction-container {
    display: flex;
    flex-direction: row;
    gap: 0.3em;
    align-items: baseline;
    font-size: small;
    background-color: white;
    padding: 0.3em 0.4em;
    border-radius: 13px;
    color: black;
    cursor: pointer;
}

.opacity-50 {
    opacity: 0.5;
}

.message-reaction-icon {
    width: 13px;
    height: 13px;
}

.message-reaction-icon img {
    width: 100%;
    height: 100%;
}

.received-message-container {
    background: linear-gradient(333deg, rgba(35,112,202,1) 0%, rgba(68,0,155,1) 100%);
    border-radius: 0px 24px 24px 24px;
}

.sent-message-container {
    background: linear-gradient(333deg, rgb(38, 52, 69) 0%, rgba(55,61,75,.96) 100%);
    border-radius: 24px 24px 0px 24px;
}

.text-white {
    color: white;
}

.public-message-comments {
    background: #ff731d;
    box-shadow: 0px 4px 12px 0px #0000003d;
    border-radius: 24px;
    padding: 1em;
    height: 90%;
}

.public-message-comments > p {
    color: white;
}

.public-message-comments .comment {
    padding: 8px;
    border-radius: 12px;
    gap: 8px;
    background: #fff7e9;
}

.public-message-comments-container {
    height: 50vh;
    overflow-y: scroll;
}

.comment-time {
    font-size: x-small;
    color: #8f9cb3;
}

.comment-avatar {
    border-radius: 50% !important;
}

.comment-message-time {
    color: #fff7e9;
    font-size: small;
}

.no-comment-container {
    height: 30vh;
    color: white;
    font-size: large;
}

.character-profile-right-container {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.ai-profiles-tab-container {
    width: 100%;
    border: 1px solid #B7C1D3;
}

@media only screen and (max-width: 768px) {
    .character-profile-right-container {
        width: 100%;
    }

    .public-chat-item {
        cursor: pointer;
        border: 4px solid #e5e5e5;
        width: 100%;
    }
}

.public-pagination-messages-container {
    height: 90%;
}

.public-messages-container {
    height: 90%;
    overflow-y: scroll;
}

.ai-character-modal-avatar {
    width: 30px!important;
    border-radius: 50%!important;
}

.character-multi-image-container {
    display: flex ;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.1em;
    padding: 1em;
}


.character-multi-image {
    width: 230px;
    height: 200px;
}

@media screen and (max-width: 1200px) {
    .character-multi-image {
        width: 300px;
        height: 250px;
    }
    .character-multi-image-container {
        gap: 2em;
    }
}

@media screen and (max-width: 1087px) {
    .character-multi-image {
        width: 255px;
        height: 200px;
    }
}

@media screen and (max-width: 960px) {
    .character-multi-image {
        width: 200px;
        height: 200px;
    }
}

@media screen and (max-width: 802px) {
    .character-multi-image {
        width: 300px;
        height: 200px;
    }

    .character-multi-image-container {
        justify-content: center;
    }
}

.character-multi-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ReactModal__Content {
    background-color: #5536A0!important;
}

/* -------------------------------------------------------------------------- */
.header-btn{
    all: unset;
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 10px;
    padding: 1px;
}

.header-edit-btn{
    all: unset;
    background-color: #272727;
    border-radius: 10px;
    padding: 1px;
    border: 1px solid #F8DD5B;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.header-btn-content{
    display: block;
    background-color: #272727;
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-size: small;
    text-align: center;
}

.header-card{
    height: 40px;
    width: 120px;
    display: block;
    background-color: var(--color-300);
    border-radius: 10px;
    padding: 1px;
}

.photos-section img {
    border-radius: 8px;
    border: 2px solid #ddd;
}

.btn-gradient:hover {
    opacity: 0.9;
}

.header-btn{
    all: unset;
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 10px;
    padding: 1px;
}

.header-btn-content{
    display: block;
    background-color: #272727;
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-size: small;
    text-align: center;
}

.header-card{
    height: 40px;
    width: 120px;
    display: block;
    background-color: var(--color-300);
    border-radius: 10px;
    padding: 1px;
}

.badge{
    background-color: var(--color-200);
    border-radius: 20px;
    font-family: poppins;
    font-weight: normal;
}

.read-more-btn{
    background-color: transparent;
    border: none;
    color: #F7DE5E;
    font-size: small;
    text-decoration: underline;
    cursor: pointer;
}

.category-title{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}

.category-content{
    font-family: poppins;
    font-weight: lighter;
}

.character-image-container {
    width: 380px;
    height: 450px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    border-radius: 10px;
}

.character-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.character-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thumbnail-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border: 2px solid transparent;
    border-radius: 10px;
}

.thumbnail-image.selected {
    border-image: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-image-slice: 2;
}


.modal-title {
    font-size: 1.5rem; /* Adjust title font size if needed */
}

.photo-modal-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px; /* Ensure rounded corners */
}

.photo-modal-body {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Add space between images */
}

/* Large Image Popup Overlay */
.large-photo-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup content container */
.large-photo-popup-content {
    position: relative;
    text-align: center;
    width: 400px;  /* Set the width of the popup */
    height: 400px; /* Set the height of the popup */
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    overflow: hidden; /* Hide anything that exceeds the fixed size */
}

/* Image in the popup */
.large-photo {
    width: 100%;      /* Make the image fill the container's width */
    height: 100%;     /* Make the image fill the container's height */
    object-fit: cover; /* Make sure the image covers the container without distortion */
}

/* Close button for the large image popup */
.large-photo-popup-content .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    color: white;
    background: transparent;
    border: none;
    cursor: pointer;
}

.reviews-section {
    padding: 20px;
}

.reviews-section .review-card {
    background: #D1D4FF1A;
    border-radius: 10px;
    width: 300px;
    height: 100px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden; /* Prevent content overflow */
    padding: 5px;
    backdrop-filter: blur(10px);
}

.reviews-section .review-card .image{
    border-radius: 10px;
    width: 80px;
    height: 80px;
    padding: 2px;
    object-fit: cover;
}

.reviews-section .review-card p {
    font-size: 10px;
    max-width: 100px;
    color: rgba(255, 255, 255, 0.7);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.2em;
    max-height: 3.6em;
    margin-bottom: 8px;
}

.reviews-section .review-card .btn {
    margin-top: 60px;
    align-self: end;
}

.reviews-section .write-review-card {
    background: #1e1e2f;
    border: 2px dashed white;
    border-radius: 12px;
    width: 300px;
    height: 100px;
    color: white;
    text-align: center;
    cursor: pointer;
}

.reviews-section .write-review-card:hover {
    opacity: 0.9;
}

.reviews-section .btn-gradient {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    width: 280px;
    height: 80px;
    color: white;
    border: none;
    font-size: 16px;
    border-radius: 12px;
    padding: 10px;
    transition: 0.3s ease;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #020202C2;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    background: #D1D4FF1A;
    backdrop-filter: blur(10px);
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    color: white;
}

.review-container {
    background: #16161A;
    border-radius: 10px;
    padding: 0 10px;
}

.input-label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    color: white;
}

.rate-section {
    margin-top: 2px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.rate-label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    color: white;
}

.form-control {
    width: 100%;
    font-size: 12px;
    border-radius: 8px;
    background: #D1D4FF1A;
}

.form-control ::placeholder {
    color: #16161A;
    opacity: 0.5;

}

.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.button-container button {
    flex: 1;
    max-width: 300px;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.cancel-button {
    background-color: #636363;
    color: #FFFFFF;
}

.cancel-button:hover {
    background-color: #bbb; /* Slightly darker gray on hover */
}

.submit-button {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    color: #fff;
}

.submit-button:hover {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 10%, rgba(216, 20, 48, 1) 70%, rgba(60, 67, 196, 1) 110%)
}

.rating-indicator {
    display: flex;
    align-items: center;
    color: #ffd700;
}

.rating-value {
    font-family: Poppins;
    font-size: 14px;
    color: #fff;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
}

.close-button:hover {
    color: #ff0000;
}

.current-url {
    width: 100%;
    padding: 5px;
}

.button-container .done-button {
    background-color: #636363;
    color: #FFFFFF;
}

.url-section .copy-button {
    background-color: #636363;
    color: #FFFFFF;
}

.button-container .done-button:hover {
    background-color: #636363;
    color: #FFFFFF;
}

.url-section .copy-button:hover {
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 10%, rgba(216, 20, 48, 1) 70%, rgba(60, 67, 196, 1) 110%);
    color: #FFFFFF;
}

.related-characters-section {
    padding: 20px;
}

.related-characters-section .card-display {
    display: flex;
    flex-wrap: wrap; /* Ensure cards wrap */
    justify-content: center;
    gap: 10px; /* Adjust spacing */
    max-height: 660px; /* Ensure the default view is for 6 cards */
    transition: max-height 0.3s ease-in-out;
}

.character-card, .character-placeholder {
    aspect-ratio: 3 / 4; /* Maintain portrait ratio */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border-radius: 8px;
    text-align: left;
    margin: auto;
}

.related-character-image-container {
    width: 180px;
    height: 300px;
}

.related-character-image-container .character-image {
    width: 180px;
    height: 300px;
    border-radius: 10px;
}

.name-rating-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.name-rating-container .star-rating-section {
    display: flex;
    gap: 0px;
}

.character-name, .character-rating, .character-creator {
    font-size: 12px; /* Reduce text size */
    margin: 3px 0; /* Adjust spacing */
}

.related-characters-section .d-flex {
    gap: 6px; /* Reduce gap between cards */
    overflow-x: auto;
    background-color: #5536A0;
}

.spinner-loading {
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    background-color: #00000088;
    height: 100vh;
}