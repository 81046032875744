.search-container {
 display: flex;
 flex-direction: row;
 margin: 1em;
 width: 100%;
 padding-right: 2%;
}

#category-input {
    width: 30%;
}

.bg-selected {
    background-color: #FEAF1A;
    color: white!important;
}

.scroll-arrow-container{
    z-index: 1;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    background: transparent;
    border: none;
}

.scroll-arrow-container::before{
    z-index: -1;
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 50px;
    background: linear-gradient(164deg, rgba(43,110,209,1) 0%, rgba(6,37,80,1) 100%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
                mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
    pointer-events: none;
}

.top-creator-container{
    overflow-x: scroll;
}

.top-creator-container::-webkit-scrollbar {
    display: none;
}

.top-creator-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.top-leaderboard-container{
    overflow: hidden;
    margin-right: 50px;
}

.platform-pic-container{
    width: 300px;
    z-index: 10;
    margin-top: -70px;
}

.platform-pic{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.char-card{
    z-index: 20;
    width: 100px;
}

.character-card-leaderboard-avatar-container{
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    background-color: black;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid #F7DE5E;
}

.character-card-leaderboard-avatar{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.char-first{
    margin-top: 20px;
}

.char-second{
    margin-top: 45px;
}

.char-third{
    margin-top: 60px;
}

.explore-category-button{
    background-color: #272727;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
}

.char-name-text{
    font-size: 14px;
}

.character-card-explore-container{
    display: flex;
    flex-direction: column;
    width: 190px;
    height: 290px;
}

.character-card-explore-image-container{
    width: 190px;
    height: 250px;
}

.character-card-explore-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.hashtags-container{
    width: 150px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #717171;
}

.hashtag-text{
    color: #717171;
    margin-right: 10px;
}

.border-line{
    border-bottom: 1px solid white;
}

.explore-category-button-selected{
    border: 1px solid #F7DE5E;
}

.featured-carousel{
    height: 300px;
    width: 100%;
}

.featured-character-info {
    position: absolute;
    width: 100%;
    bottom: 0px;
    color: white;
    padding: 12px 12px 40px 12px;
    z-index: 10;
    background: linear-gradient(0deg,rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0,0) 100%);
}

.featured-card-explore-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;    
}

.featured-character-description{
    text-overflow: ellipsis;
    color: white;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
}

.see-all-button{
    all: unset;
    color: #9D9D9D;
    text-decoration: underline;
    border: none;
    text-align: center;
    font-size: 12px;
}

.top-leaderboard-category-button-container{
    display: flex;
    width: 100%;
    background-color: #D1D4FF1A;
    padding: 8px;
    border-radius: 8px;
}

.top-leaderboard-category-button{
    color: white;
    font-size: 12px;
    padding: 8px 0px;
    border-radius: 8px;
    background-color: #D1D4FF1A;
    border: 1px solid #D1D4FF1A;
    width: 50%;
}

.top-leaderboard-category-button-selected{
    color: white;
    font-size: 12px;
    padding: 8px 0px;
    border-radius: 8px;
    background-color: #272727;
    border: 1px solid #F7DE5E;
    width: 50%;
}